import restaurant from "./images/Mi-Piace.jpg";
import biserica from "./images/condrita.jpg";
import img_card from "./images/bal2.png";
import imgheadermb from "./images/m7.png";
import imgheader from "./images/m2.png";
import imgheadermiini from "./images/bal4.webp";
import imgheadermiinimb from "./images/bal4.webp";
import logo from './images/bal.gif'



const data = {
    introData: [{
        copilul: "Victoria",
        familia: "familia Voloc",
        logo: logo,
        tata: "Victor",
        mama: "Elena",
        data: "12 Iulie 2023",
        data_confirmare: "2 iulie 2023",
        savedata: "~ Vă invită! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "lenuta2688@gmail.com", 
       tel: "+373 792 666 26",
       phone: "tel:+37379266626",
       viber: "viber://chat?number=%2B37379266626",
       whatsapp: "https://wa.me/+37379266626",
       messenger: "https://www.messenger.com/t/elena.gotonoaga",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Sf. Botez",
            localul: "Mănăstirea",
            name: "Condrița",
            data: "12 iulie 2023, miercuri, ora 16:00",
            adress: "s. Condrița",
            harta: "https://goo.gl/maps/8HjfcDrgQfgBpS5f6",
            iframe: ""
        },
        {
            id: 2,
            img: restaurant,
            title: "Petrecerea Evenimentului",
            localul: "Restaurantul",
            name: "Mi Piace",
            data: "12 iulie 2023, miercuri, ora 18:00",
            adress: "Strada Trandafirilor 4, Chişinău",
            harta: "https://goo.gl/maps/ZTxP8rhXGNdbVHTi9",
            iframe: ""
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Un copil este un dar al lui Dumnezeu, un motiv de a crede în minuni, este pur și simplu fericirea pe Pământ.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: "",
            title1: "Invitație",
            title2: "Nunta noastră!",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;